const courseSettings = {
  language: "en",
  name: "Big Data Platforms, Autumn 2024",
  siteUrl: "https://big-data-platforms-24.mooc.fi/",
  githubUrl: "https://github.com/rage/big-data-platforms-24/",
  subtitle: "Introduction to Big Data Processing Frameworks",
  slug: "big-data-platforms-24",
  tmcCourse: "",
  quizzesId: "4fa507b1-55f1-4872-91a3-fa6c860be805",
  tmcOrganization: "hy",
  organizationName: "University of Helsinki",
  bannerPath: "banner.jpg",
  courseId: "80b70240-25cf-4af5-8b7b-480e2b1af416",
}

module.exports = courseSettings
